<!-- #################################################################################### -->
<!-- ###### HERINCOHAN                                                             ###### -->
<!-- ###### @author: Estefania Villada Chavarria                                   ###### -->
<!-- ###### @date: Abril 2024                                                      ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
  <div class="d-flex">
    <SubMenu :nameModule="nameModule" :subMenu="subMenu" />

    <!-- Seccion para cargar el contenido de la navegación del modulo-->
    <v-slide-y-transition mode="out-in">
      <router-view></router-view>
    </v-slide-y-transition>
  </div>
</template>
<!-- #################################################################################### -->
<!-- ###### Sección de Script                                                      ###### -->
<!-- #################################################################################### -->
<script>
import { mapState } from "vuex";
import SubMenu from '@/components/SubMenu.vue';
import { Role } from "../../../router/role.js";

export default {
  name: 'HumanTalent',
  components: { SubMenu },
  data: () => ({
    tab: null,
    nameModule: "Talento Humano",
    userRoles: {},
    roles: Role,
    subMenu: [
      {
        name: "Evaluación de Desempeño",
        icon: "quiz",
        rol: Role.TalentoHumano_EvaluacionDesempeno,
        link: { name: 'modules.humanTalent.performanceEvaluation' }
      },
      {
        name: "Modelo de Competencias",
        icon: "assignment",
        rol: Role.TalentoHumano_ModeloCompetencias,
        link: { name: 'modules.humanTalent.competencyModel' }
      },
      {
        name: "Estructura",
        icon: "lan",
        rol: Role.TalentoHumano_Estructura,
        link: { name: 'modules.humanTalent.structure' }
      },
    ],
  }),
  computed: {
    ...mapState(["auth"]),
  },
  created() {
    this.userRoles = this.auth.roles;
  },
}
</script>
<!-- #################################################################################### -->
<!-- ###### Sección de Style                                                       ###### -->
<!-- #################################################################################### -->
<style scoped></style>